
.number-field {
    position: relative;
}

.empty-number-field input {
    background-color: #EFEFEF !important;
    border: 1px solid transparent !important;
}

.number-field label {
    font-family: Inter;
    font-weight: 500;
    font-size: 10px;
    color: #121212;
    margin-bottom: 8px;
}

.number-field input {
    background-color: transparent;
    border: 1px solid #C1BFB0;
    border-radius: 6px;
    height: 50px;
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
    text-align: inherit;
}

.number-field .text-right {
    position: relative;
    left: -17px;
    font-size: 10px;
    color: #8C8A81;
    font-family: Inter;
}

.number-field-rounded input {
    border-radius: 20px !important;
}

.number-field .error {
    position: absolute;
    bottom: -16px;
    font-size: 11px;
    color: #c30000;
}