
.checkbox {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 22px;
    height: 25px;
    display: flex;
    align-items: center;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox .mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #EFEFEF;
    border-radius: 6px;
    /*border: 2px solid #000000;*/
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.checkbox input:checked ~ .mark {
    background-color: #FC634B;
    border-color: #FC634B;
}

.checkbox .mark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .mark:after {
    display: block;
}

.checkbox input:not(:checked) ~ .mark > img {
    display: none;
}
