@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .orange-filter {
       filter: invert(57%) sepia(37%) saturate(4173%) hue-rotate(328deg) brightness(97%) contrast(104%);
    }
}

body {
    background-color: #EFEFEF;
    color: #313131;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Poppins";
}

code {
    font-family: "Poppins", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* FONTS */

@font-face {
    font-family: "Inter";
    font-style: normal;
    src: local("Inter"),
    url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Medium";
    font-style: normal;
    src: local("Inter-Medium"),
    url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-SemiBold";
    font-style: normal;
    src: local("Inter-SemiBold"),
    url("./fonts/Inter-SemiBold.ttf") format("truetype");
}



/* FONT-SIZE */

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-16 {
    font-size: 16px;
}

.fs-20 {
    font-size: 20px
}

.fs-28 {
    font-size: 28px;
}

.fs-32 {
    font-size: 32px;
}

/* FONT COLOR */

.fc-primary {
    color: #1F1F1F;
}

.fc-light {
    color: #A1A1A1;
}

.fc-error {
    color: #7e0000;
}

.fc-white {
    color: #FFFFFF;
}

.fc-medium-gray {
    color: #7c7c7c
}

.fc-dark-gray {
    color: #494949;
}

/* FONT TRANSFORM */

.capitalize {
    text-transform: capitalize;
}

.lowercase {
    text-transform: lowercase;
}

.uppercase {
    text-transform: uppercase;
}

/* FONT WEIGHT */
.font-thin {
    font-weight: 100;
}

.font-extralight {
    font-weight: 200;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-extrabold {
    font-weight: 800;
}

.font-black {
    font-weight: 900;
}


/* MARGINS */

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mb-40 {
    margin-bottom: 40px;
}

/* POSITION */

.flex {
    display: flex;
}

.right {
    justify-content: right;
}

.center {
    align-items: center;
}

.basis-350 {
    flex-basis: 350px;
}

.no-shrink {
    flex-shrink: 0;
}

.grow {
    flex-grow: 1;
}

.column {
    flex-direction: column;
}


/* WIDTH */

.w-80 {
    width: 80px;
}

.w-100 {
    width: 100px;
}

.w-120 {
    width: 120px;
}

.w-400 {
    width: 400px;
}

.w-100-pct {
    width: 100%;
}

.w-80-pct {
    width: 80%;
}

.w-60-pct {
    width: 60%;
}

.w-40-pct {
    width: 40%;
}

.w-30-pct {
    width: 30%;
}


.w-20-pct {
    width: 20%;
}

.w-10-pct {
    width: 10%;
}


@media only screen and (min-width: 992px) {

    .w-100-pct-md {
        width: 100%;
    }

    .w-80-pct-md {
        width: 80%;
    }

    .w-60-pct-md {
        width: 60%;
    }

    .w-40-pct-md {
        width: 40%;
    }

    .w-20-pct-md {
        width: 20%;
    }

    .w-10-pct-md {
        width: 10%;
    }

}

@media only screen and (min-width: 1200px) {

    .w-100-pct-lg {
        width: 100%;
    }

    .w-80-pct-lg {
        width: 80%;
    }

    .w-60-pct-lg {
        width: 60%;
    }

    .w-40-pct-lg {
        width: 40%;
    }

    .w-20-pct-lg {
        width: 20%;
    }

}

@media only screen and (min-width: 1600px) {

    .w-100-pct-xl {
        width: 100%;
    }

    .w-80-pct-xl {
        width: 80%;
    }

    .w-60-pct-xl {
        width: 60%;
    }

    .w-40-pct-xl {
        width: 40%;
    }

    .w-20-pct-xl {
        width: 20%;
    }

}

.delete-action {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-action:hover {
    cursor: pointer;
    background-color: rgba(182, 182, 182, 0.36);
}

.nowrap {
    white-space: nowrap;
}

.dropzone {
    border: 2px dashed #D6D5D1;
    border-radius: 6px;
    padding: 32px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #313131;
}

.dropzone:hover {
    cursor: pointer;
    border: 2px dashed #b9b9b9;
}

.dropzone .upload-img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E0DED4;
    border: solid 8px #e0ded44d;
}

.section-id {
    font-size: 12px;
    color: #313131;
    display: flex;
    align-items: center;
}

.section-id span:nth-child(1) {
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    background-color: #FF634A;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-id span:nth-child(2) {
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
    color: #313131;
    vertical-align: middle;
}

.section {
    background-color: white;
    border-radius: 8px;
    padding: 32px 16px;
}

.info-box-shadow {
    box-shadow: 0 4px 52px 0 rgba(0, 0, 0, 0.13);
}

.modal-box-shadow {
    box-shadow: 0 4px 52px 0 rgba(0, 0, 0, 0.13);
}

input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #313131;
    opacity: 0.6;

}

.text-orange {
    color: #FC634B;
}

.text-gray {
    color: #4D4D4D
}

.optional-input-indicator {
    font-size: 12px;
    font-weight: 400;
    color: #313131;
    margin-left: 8px;
}