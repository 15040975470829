.Steps {
    border-radius: 16px;
    padding: 50px 20px;
    position: sticky;
    top: 80px;
}

.Steps .space {
    height: 16px;
    border-left: 2px solid black;
    position: relative;
    margin: 10px 18px;
}