
.Form {
    display: grid;
    grid-template-columns: 2fr 280px  repeat(9, 1fr) 2fr;
    background-color: #EFEFEF;
    min-height: 100vh;
    padding-bottom: 100px;
}

.Form .status {
    grid-column: 2/3;
}

.Form .content {
    grid-column: 3/12;
}

.Form > .title {
    grid-column: 2/12;
    font-size: 30px;
    padding: 20px;
    margin-top: 20px;
}