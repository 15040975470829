
.Modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.62);
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Modal > .content {
    background-color: white;
    min-height: 300px;
    min-width: 200px;
    max-height: 80vh;
    max-width: 80%;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 52px 0px rgba(0, 0, 0, 0.13);
}

.Modal .header {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #1F1F1F;
    padding: 25px;
}

.Modal .body {
    padding: 25px 25px 25px 25px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.Modal .body::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.Modal .footer {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -2px 22px rgb(0 0 0 / 11%);
    padding: 0 20px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 0 0 12px 12px;
}

.Modal .footer .invalid {
    height: 80px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #ffffff8c;
    border-radius: 0 0 12px 12px;
}

.modal-close-btn {
    width: 32px;
    height: 32px;
    /*background-color: #ECE9E1;*/
    border-radius: 8px;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-close-btn:hover {
    cursor: pointer;
    background-color: #efedeb;
}